import {uniqBy} from 'lodash';
import {forwardRef, MouseEventHandler, useMemo} from 'react';
import {twMerge} from 'tailwind-merge';
import clsx from 'clsx';
import {escapeRegExp} from '../../../components/InputDate/InputDate.helpers';

/* eslint react/require-default-props: 0 */
export type HighlightProps = {
  text: string;
  phrase: string | string[];
  highlightClassName?: string;
  className?: string;
  caseSensitive?: boolean;
  firstMatchOnly?: boolean;
  onClick?: () => void;
  ellipsis?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
  testId?: string;
};

type MatchType = {0: string; index: number};

/* eslint react/require-default-props: 1 */
export const getSlices = (
  text: string,
  phrases: string[],
  caseSensitive: boolean,
  firstMatchOnly: boolean,
): [string, boolean][] => {
  let matches = Array.from(
    (caseSensitive ? text : text.toLowerCase()).matchAll(
      phrases.map(s => escapeRegExp(caseSensitive ? s : s.toLowerCase())).join('|') as unknown as RegExp,
    ),
  ) as MatchType[];

  if (firstMatchOnly) {
    matches = uniqBy(matches, '0');
  }

  const output: [string, boolean][] = [];

  if (matches.length === 0) {
    return [[text, false]];
  }

  output.push([text.slice(0, matches[0].index), false]);

  for (let i = 0; i < matches.length; i += 1) {
    output.push([text.slice(matches[i].index, matches[i].index + matches[i][0].length), true]);
    if (i < matches.length - 1) {
      output.push([text.slice(matches[i].index + matches[i][0].length, matches[i + 1].index), false]);
    } else {
      output.push([text.slice(matches[i].index + matches[i][0].length), false]);
    }
  }

  return output.filter(([s]) => s.length > 0);
};

export const Highlight = forwardRef<HTMLSpanElement, HighlightProps>(
  (
    {
      caseSensitive = false,
      className = '',
      text,
      phrase,
      highlightClassName = 'bg-primary-100',
      firstMatchOnly = true,
      onClick = undefined,
      ellipsis = false,
      onMouseEnter = undefined,
      onMouseLeave = undefined,
      testId = 'highlight',
    },
    ref,
  ) => {
    const slices = useMemo<[[string, boolean], string][]>(
      () =>
        getSlices(text || '', typeof phrase === 'string' ? [phrase] : phrase, caseSensitive, firstMatchOnly).map(
          slice => [slice, crypto.randomUUID()],
        ),
      [text, phrase, caseSensitive, firstMatchOnly],
    );

    const applyEllipsis = ellipsis && firstMatchOnly;

    return (
      <span
        className={twMerge(
          clsx({'whitespace-pre': !applyEllipsis, 'whitespace-nowrap flex': applyEllipsis}, className),
        )}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
        data-testid={testId}
      >
        {slices.map(([[sliceText, isHighlighted], key], index) => (
          <span
            dir={applyEllipsis && index === 0 && slices.length > 1 ? 'rtl' : 'ltr'}
            key={key}
            className={twMerge(
              clsx({
                'space-before':
                  (index === 0 && applyEllipsis && sliceText.endsWith(' ') && sliceText.length > 1) ||
                  (applyEllipsis && sliceText.startsWith(' ')),
                'space-after': index > 0 && applyEllipsis && sliceText.endsWith(' ') && sliceText.length > 1,
                [highlightClassName]: isHighlighted,
                'grow-0 shrink-0': applyEllipsis && isHighlighted,
                'whitespace-nowrap': applyEllipsis && !isHighlighted,
                'overflow-hidden text-ellipsis whitespace-nowrap grow shrink':
                  applyEllipsis && !isHighlighted && [0, slices.length - 1].includes(index),
              }),
            )}
          >
            {sliceText}
          </span>
        ))}
      </span>
    );
  },
);
