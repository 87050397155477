import React, {FC, forwardRef, PropsWithChildren, RefObject, useCallback, useEffect, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import {debounce} from 'lodash';
import {getVPos, getWPos, PositionViewportModel, RectObserver} from './Portal.helpers';

const modalRootID = `modal-portal-root`;
type PaintState = 'invisible' | 'fade-in' | 'visible' | 'fade-out';
type OriginType = {
  vertical?: 'bottom' | 'center' | 'top';
  horizontal: 'left' | 'center' | 'right';
};

export enum PortalCloseSource {
  BACKDROP,
  ESC_BUTTON,
}

/* eslint react/require-default-props: 0 */
export type PortalProps = {
  Junction?: FC<{placement: Required<OriginType>['vertical']}>;
  anchorEl?: RefObject<HTMLElement | HTMLBodyElement | null>;
  anchorOrigin?: OriginType;
  backdrop?: boolean | 'transparent';
  className?: string;
  portalClassName?: string;
  isTooltip?: boolean;
  onClosePortal?: (source?: PortalCloseSource) => void;
  onClick?: (event: MouseEvent) => void;
  onMouseEnter?: (mouseEvent: MouseEvent) => void;
  onMouseLeave?: (mouseEvent: MouseEvent) => void;
  paintState?: PaintState;
  transformOrigin?: OriginType;
  zIndex?: number;
  testId?: string;
};
/* eslint react/require-default-props: 1 */

const getModalRoot = () => {
  if (document.querySelector(`#${modalRootID}`) === null) {
    const modalRoot = document.createElement('div');
    modalRoot.setAttribute('id', modalRootID);
    modalRoot.style.position = 'fixed';
    modalRoot.style.top = '0px';
    modalRoot.style.left = '0px';
    modalRoot.style.zIndex = '9999';
    document.body.append(modalRoot);
  }
  return document.querySelector(`#${modalRootID}`) as HTMLDivElement;
};

const getElevationSlot = (elevation: number) => {
  const modalRoot = getModalRoot();
  if (modalRoot.querySelector(`[data-elevation='${elevation}']`) === null) {
    const elevationSlot = document.createElement('div');
    elevationSlot.setAttribute('data-elevation', `${elevation}`);
    elevationSlot.style.position = 'fixed';
    elevationSlot.style.top = '0px';
    elevationSlot.style.left = '0px';
    elevationSlot.style.zIndex = `${elevation}`;
    modalRoot.append(elevationSlot);
  }
  return modalRoot.querySelector(`[data-elevation='${elevation}']`) as HTMLDivElement;
};

const insertElement = (
  portalProps: Pick<PortalProps, 'backdrop' | 'zIndex'>,
): {portalEl: HTMLDivElement; backdropEl: HTMLDivElement} => {
  const slot = getElevationSlot(portalProps.zIndex as number);
  const backdropEl = document.createElement('div');

  if (portalProps.backdrop) {
    backdropEl.style.position = 'fixed';
    backdropEl.style.top = '0px';
    backdropEl.style.left = '0px';
    backdropEl.style.width = '100vw';
    backdropEl.style.height = '100vh';
    if (portalProps.backdrop !== 'transparent') {
      backdropEl.style.background = 'rgba(0,0,0,.2)';
    }
    slot.append(backdropEl);
  }

  const portalEl = document.createElement('div');
  portalEl.style.position = 'absolute';
  portalEl.style.width = 'max-content';
  portalEl.style.maxWidth = '99vw';
  portalEl.style.height = 'max-content';
  portalEl.style.maxHeight = '99vh';
  slot.append(portalEl);

  return {
    portalEl,
    backdropEl,
  };
};

const calculateTransformXY = (
  anchorPos: PositionViewportModel,
  anchorOrigin: OriginType,
  portalEl: HTMLElement,
  portalOrigin: OriginType,
  isTooltip: boolean,
) => {
  let x = 0;
  let y = 0;

  if (anchorOrigin.horizontal === 'center') {
    x += anchorPos.width * 0.5;
  }
  if (anchorOrigin.horizontal === 'right') {
    x += anchorPos.width;
  }
  if (portalOrigin.horizontal === 'center') {
    x -= portalEl.offsetWidth * 0.5;
  }
  if (portalOrigin.horizontal === 'right') {
    x -= portalEl.offsetWidth;
  }

  if (anchorOrigin.vertical && portalOrigin.vertical) {
    if (anchorOrigin.vertical === 'center') {
      y += anchorPos.height * 0.5;
    }
    if (anchorOrigin.vertical === 'bottom') {
      y += anchorPos.height;
    }
    if (portalOrigin.vertical === 'center') {
      y -= portalEl.offsetHeight * 0.5;
    }
    if (portalOrigin.vertical === 'bottom') {
      y -= portalEl.offsetHeight;
    }
  } else if (isTooltip) {
    if (portalEl.getBoundingClientRect().height < anchorPos.top) {
      y -= portalEl.offsetHeight;
    } else {
      y += anchorPos.height;
    }
  } else if (portalEl.getBoundingClientRect().height < window.innerHeight - anchorPos.top - anchorPos.height) {
    y += anchorPos.height;
  } else {
    y -= portalEl.offsetHeight;
  }

  const windowWidth =
    document.body.parentElement?.getBoundingClientRect()?.width ||
    document.body.getBoundingClientRect()?.width ||
    window.innerWidth;

  const leftFix = x + anchorPos.left;
  if (leftFix < 0) {
    x -= leftFix;
  } else {
    const rightFix = windowWidth - (x + anchorPos.left + portalEl.offsetWidth);
    if (rightFix < 0) {
      x += rightFix;
    }
  }

  const windowHeight = Math.max(
    document.body.parentElement?.getBoundingClientRect()?.height || 0,
    document.body.getBoundingClientRect()?.height,
    window.innerHeight,
  );

  const topFix = y + anchorPos.top;
  if (topFix < 0) {
    y -= topFix;
  } else {
    const bottomFix = windowHeight - (y + anchorPos.top + portalEl.offsetHeight);
    if (bottomFix < 0) {
      y += bottomFix;
    }
  }

  return `translate(${x}px, ${y}px)`;
};

type JunctionPlacementProps = {top: string; left: string; placement: Required<OriginType>['vertical']};
const getJunctionPosition = (
  portalEl: HTMLElement,
  anchorPosition: PositionViewportModel,
  transformOrigin: OriginType,
): JunctionPlacementProps => {
  let junctionOrigin: Required<OriginType>['vertical'] = transformOrigin.vertical as Required<OriginType>['vertical'];
  const top = (() => {
    if (transformOrigin.vertical === 'top') {
      return '0%';
    }
    if (transformOrigin.vertical === 'center') {
      return '50%';
    }
    if (transformOrigin.vertical === 'bottom') {
      return '100%';
    }
    if (portalEl.getBoundingClientRect().height < anchorPosition.top) {
      junctionOrigin = 'bottom';
      return '100%';
    }
    junctionOrigin = 'top';
    return '0%';
  })();

  const left = (() => {
    const portalRect = portalEl.getBoundingClientRect();
    const junctionX1 = Math.max(portalRect.left, anchorPosition.left);
    const junctionX2 = Math.min(portalRect.left + portalRect.width, anchorPosition.left + anchorPosition.width);
    const junctionX = (junctionX1 + junctionX2) / 2;
    return `${junctionX - portalRect.left}px`;
  })();

  return {top, left, placement: junctionOrigin};
};

const JunctionInternals: FC<JunctionPlacementProps & {Junction: FC<{placement: Required<OriginType>['vertical']}>}> = ({
  left,
  top,
  placement,
  Junction,
}) => {
  return (
    <div style={{top, left, width: '0', height: '0', position: 'absolute', overflow: 'visible', pointerEvents: 'none'}}>
      <Junction placement={placement} />
    </div>
  );
};

const PortalInternals = forwardRef<HTMLDivElement, PropsWithChildren<PortalProps>>(
  (
    {
      transformOrigin = {vertical: 'center', horizontal: 'center'},
      anchorOrigin = {vertical: 'center', horizontal: 'center'},
      onClosePortal = undefined,
      backdrop = true,
      children = undefined,
      anchorEl = undefined,
      zIndex = 1000,
      className = undefined,
      Junction = undefined,
      paintState = 'visible',
      portalClassName = undefined,
      onMouseEnter = undefined,
      onMouseLeave = undefined,
      onClick = undefined,
      isTooltip = false,
      testId = 'portal',
    },
    ref,
  ) => {
    const portalElRef = useRef<HTMLDivElement>();
    const junctionPosRef = useRef<JunctionPlacementProps>();
    const [, setRenderStamp] = useState<symbol>();

    const onClosePortalRef = useRef(onClosePortal);
    onClosePortalRef.current = onClosePortal;

    useEffect(() => {
      const {portalEl, backdropEl} = insertElement({zIndex, backdrop});
      portalElRef.current = portalEl;

      const onBackdropClick = () => {
        if (onClosePortalRef.current) {
          onClosePortalRef.current(PortalCloseSource.BACKDROP);
        }
      };

      if (backdropEl) {
        backdropEl.addEventListener('click', onBackdropClick);
      }
      if (onMouseEnter) {
        portalEl.addEventListener('mouseenter', onMouseEnter);
      }
      if (onMouseLeave) {
        portalEl.addEventListener('mouseleave', onMouseLeave);
      }
      if (onClick) {
        portalEl.addEventListener('click', onClick);
      }

      const callback = (): void => {
        const position = anchorEl ? getVPos(anchorEl.current as HTMLElement) : getWPos();
        portalEl.style.transform = `translate(${-1000}vw, ${-1000}vh)`;
        portalEl.style.top = `${position.top}px`;
        portalEl.style.left = `${position.left}px`;
        setRenderStamp(Symbol('render'));

        requestAnimationFrame(() => {
          portalEl.style.transform = calculateTransformXY(position, anchorOrigin, portalEl, transformOrigin, isTooltip);
          junctionPosRef.current = getJunctionPosition(portalEl, position, transformOrigin);
          setRenderStamp(Symbol('render'));
        });
      };

      const onEscButtonClick = (event: KeyboardEvent) => {
        if (onClosePortal && event.key === 'Escape') {
          onClosePortal(PortalCloseSource.ESC_BUTTON);
        }
      };

      const debouncedCallback = debounce(callback);
      const ro = new RectObserver(debouncedCallback);
      if (anchorEl && anchorEl.current) {
        ro.observe(anchorEl.current);
        document.addEventListener('scroll', debouncedCallback);
      }

      requestAnimationFrame(callback);
      const reo = new ResizeObserver(debouncedCallback);
      reo.observe(portalEl);

      document.addEventListener('scroll', debouncedCallback);
      window.addEventListener('resize', debouncedCallback);
      window.addEventListener('keydown', onEscButtonClick);

      return () => {
        if (backdropEl) {
          backdropEl.removeEventListener('click', onBackdropClick);
        }
        if (onMouseEnter) {
          portalEl.removeEventListener('mouseenter', onMouseEnter);
        }
        if (onMouseLeave) {
          portalEl.removeEventListener('mouseleave', onMouseLeave);
        }
        if (onClick) {
          portalEl.removeEventListener('click', onClick);
        }

        document.removeEventListener('scroll', debouncedCallback);
        window.removeEventListener('resize', debouncedCallback);
        window.removeEventListener('keydown', onEscButtonClick);
        ro.disconnect();
        reo.disconnect();
        portalEl.remove();
        backdropEl?.remove();
      };
    }, []);

    useEffect(() => {
      if (portalClassName) {
        portalElRef.current!.className = portalClassName;
      }
    }, [portalClassName]);

    return (
      <>
        {portalElRef.current &&
          createPortal(
            <div ref={ref} data-test-element="portal" data-testid={testId} className={className}>
              {Junction && junctionPosRef.current && (
                <JunctionInternals Junction={Junction} {...junctionPosRef.current} />
              )}
              {children}
            </div>,
            portalElRef.current,
          )}
      </>
    );
  },
);

export const Portal = forwardRef<
  HTMLDivElement,
  // eslint-disable-next-line react/require-default-props
  PropsWithChildren<PortalProps & {isOpen: boolean; transitionName?: string; duration?: number}>
>(({isOpen, duration = 0, transitionName = '', ...rest}, ref) => {
  const [paintState, setPaintState] = useState<PaintState>('invisible');

  const handleEnter = useCallback(() => {
    setPaintState('fade-in');
  }, []);

  const handleEntered = useCallback(() => {
    setPaintState('visible');
  }, []);

  const handleExit = useCallback(() => {
    setPaintState('fade-out');
  }, []);

  const handleExited = useCallback(() => {
    setPaintState('invisible');
  }, []);

  return (
    <>
      {transitionName && duration && (
        <CSSTransition
          onEnter={handleEnter}
          onEntered={handleEntered}
          onExit={handleExit}
          onExited={handleExited}
          mountOnEnter
          unmountOnExit
          in={isOpen}
          timeout={duration}
          classNames={transitionName}
        >
          <PortalInternals {...rest} paintState={paintState} ref={ref} />
        </CSSTransition>
      )}
      {(!transitionName || !duration) && isOpen && <PortalInternals {...rest} ref={ref} />}
    </>
  );
});
